/* .topia-range-slider {
}

.topia-range-slider::slider-track {
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.topia-range-slider::-ms-slider-track {
  background: transparent;
  border-color: transparent;
  color: transparent;
}
*/
/* .topia-range-slider::-moz-range-track {
  visibility: hidden;
  display: none;
  appearance: none;
} */

.topia-range-slider {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
}

.topia-range-slider::-moz-range-thumb {
  border: none;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 2.25rem;
  width: 1rem;
}

.topia-range-slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  border-radius: 4px;
  position: relative;
  bottom: -4px;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 2.25rem;
  width: 1rem;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #1d1e20;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple.white div {
  position: absolute;
  border: 4px solid #ffffff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.topia-slow-fade-in {
  animation: fade-in 1.5s ease-in-out;
  opacity: 1;
}

.topia-fade-in {
  animation: fade-in 0.5s ease-in-out;
}

.topia-delay-fade-in {
  animation: fade-in 1s ease-in-out;
  animation-delay: 4s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.topia-shrink-fade {
  animation: shrink-fade 0.3s ease-out;
}

@keyframes shrink-fade {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
}

.sarah-gpt-response li {
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 6px;
  cursor: pointer;
  opacity: 1;
  transition: all 0.2s ease-in-out;
  transform: translateY(0px) scale(1);
}

.sarah-gpt-response li:hover {
  opacity: 0.75;
  transform: translateY(-5px) scale(1.03);
}

.sarah-gpt-response li:nth-of-type(even) {
  background: #efefef;
}
.sarah-gpt-response li:nth-of-type(odd) {
  background: white;
}

.sarah-gpt-response h4 {
  font-family: "Apercu";
  font-size: 24px;
  font-weight: 1000;
  margin: 0;
}

.sarah-gpt-response p {
  margin: 0;
  margin-top: -2px;
  font-size: 18px;
}

@media screen and (max-width: 767px) {
  .sarah-gpt-response h4 {
    font-size: 16px;
  }
  .sarah-gpt-response p {
    font-size: 12px;
  }
}
