@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Romie";
  src: local("Romie"), url(./fonts/RomieTrial-Regular.otf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Romie";
  src: local("Romie"), url(./fonts/Romie-Trial-Medium.otf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Romie";
  src: local("Romie"), url(./fonts/Romie-Trial-Bold.otf);
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Romie";
  src: local("aomie"), url(./fonts/Romie-Trial-Black.otf);
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Apercu";
  src: local("apercu"), url(./fonts/ApercuProRegular.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Apercu";
  src: local("apercu"), url("./fonts/Apercu Pro Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Apercu";
  src: local("apercu"), url(./fonts/ApercuProBold.ttf);
  font-weight: 600;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Romie", sans-serif;
}

body {
  margin: 0;

  /* padding-bottom: 100px; */
  font-family: "apercu", -apple-system, BlinkmaxSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.slider .track {
  height: 10px;
  background: #fafafa;
  border-radius: 6px;
  overflow: hidden;
}

.slider .thumb {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin-top: -4px;
  cursor: grab;
  background: #75fbde;
}

.topia-bounce-in {
  animation: topia-bounce-in 0.3s ease-in-out;
}

@keyframes topia-bounce-in {
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.topia-fade-in {
  animation: topia-fade-in 0.3s ease-in-out;
}

@keyframes topia-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.topia-fade-in-up {
  animation: topia-fade-in-up 0.3s ease-in-out;
}

@keyframes topia-fade-in-up {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 20, 0);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.topia-sb-rich-text h1 {
  font-size: 48px;
  margin-bottom: 16px;
}

.topia-sb-rich-text h2 {
  font-size: 40px;
  margin-bottom: 16px;
}

.topia-sb-rich-text h3 {
  font-size: 36px;
  margin-bottom: 12px;
}

.topia-sb-rich-text h4 {
  font-size: 28px;
  margin-bottom: 12px;
}

.topia-sb-rich-text a {
  color: #da3a9a;
  font-weight: black;
  text-decoration: underline;
}

.topia-sb-rich-text h5 {
  font-size: 24px;
  margin-bottom: 12px;
}

.topia-sb-rich-text h6 {
  font-size: 20px;
  margin-bottom: 12px;
}

.topia-sb-rich-text ul {
  padding-left: 40px;
}

.topia-sb-rich-text li {
  list-style: disc;
  font-size: 18px;
}

@media screen and (max-width: 767px) {
  .topia-sb-rich-text h1 {
    font-size: 44px;
    margin-bottom: 16px;
  }
  .topia-sb-rich-text h2 {
    font-size: 38px;
    margin-bottom: 16px;
  }
}

.topia-sb-rich-text p {
  margin-bottom: 24px;
  line-height: 1.7em;
  font-size: 18px;
}

.topia-sb-rich-text li {
  margin-bottom: 4px;
  line-height: 1.7em;
  line-height: 1.7em;
  font-size: 18px;
}

.cta .cta-inner {
  transform: scale(1);
  opacity: 1;
}

.cta:hover .cta-inner {
  animation: explode 300ms ease-out;
}

@keyframes explode {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1.5);
    opacity: 0;
  }
}

.topia-chat-bubble {
  animation: pop-in 400ms ease-in-out;
}

@keyframes pop-in {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.portal-csv-content p a {
  color: #0000d6;
  font-weight: black;
  text-decoration: underline;
}

.sarah-loading-bar-inner {
  animation: sarah-loading-bar 30s ease-out;
  width: 95%;
}

@keyframes sarah-loading-bar {
  from {
    width: 1%;
  }
  to {
    width: 99%;
  }
}
